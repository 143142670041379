import { useAppDispatch, useAppSelector } from "../../../Redux/store";
import { callAPI, overallActions } from "../../../MainLayout/services/slice";
import overallAPI from "../../../MainLayout/services/api";
import { createdType } from "../../../MainLayout/services/type";
import { pageBookingEnum } from "../../../Types/routes.type";
import useGetBkey from "../../../MainLayout/hooks/useGetBkey";
import { queryEnum } from "../../../Types/global.type";

const useTakeQueue = (count: number) => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.overall.name);
  const phone = useAppSelector((state) => state.overall.phone);
  const { getKey } = useGetBkey();

  const onTakeQueue = () => {
    const client_id = localStorage.getItem(queryEnum.id);
    const createQueue = async () => {
      if (client_id)
        return await overallAPI.createQueue({
          client_id: client_id,
          kiosk_code: getKey(),
          name: name,
          phone: phone,
          count: count,
        });
    };

    const createQueueSuccess = (e: createdType) => {
      dispatch(overallActions.setTicket(e));
      dispatch(overallActions.setName(""));
      dispatch(overallActions.setPhone(""));
      dispatch(overallActions.setPageBooking(pageBookingEnum.ticket));
    };

    dispatch(callAPI(createQueue, createQueueSuccess));
  };

  return { onTakeQueue };
};

export default useTakeQueue;
