import React from "react";
import "./NotFound.scss";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return <div className="not-found"><div>{t("check qr")}</div><div>{t("Xin cảm ơn quý khách")}</div></div>;
};

export default React.memo(NotFound);
