import React, { Dispatch, FC, SetStateAction } from "react";
import "./Count.scss";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import Image from "../../../../Components/Image/Image";

interface PT {
  setCount: Dispatch<SetStateAction<number>>;
  count: number;
}

const Count: FC<PT> = ({ setCount, count }) => {
  const onMinus = () => {
    setCount((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const onAdd = () => {
    setCount((prev) => (prev < 8 ? prev + 1 : prev));
  };
  return (
    <div className="count">
      <CustomButton onClick={onMinus} className="count-btn">
        <Image className="count-minus" />
      </CustomButton>
      <div className="count-text">{count}</div>
      <CustomButton onClick={onAdd} className="count-btn">
        <Image className="count-add" />
      </CustomButton>
    </div>
  );
};

export default React.memo(Count);
