import Api from "../../Providers/Api";
import { queryEnum } from "../../Types/global.type";
import { languageEnum } from "./const";

export default class overallAPI {
  static getTables = async (kiosk_code: string) => {
    return await Api.get(
      `/api/admin/center/service/get-by-kiosk-code?kiosk_code=${kiosk_code}`
    );
  };

  static getRestaurants = async () => {
    return await Api.get(`/api/admin/province/list`);
  };

  static createQueue = async (payload: {
    client_id: string;
    kiosk_code: string;
    name: string;
    phone: string;
    count: number;
  }) => {
    return await Api.put(`/process/kiosk/qrscan_create_ticket`, {
      appoiment_code: "",
      booking_id: "",
      language: languageEnum.vn,
      thumbnail_base64: "",
      type: "QrScan",
      ...payload,
    });
  };

  static checkQueue = async (queue: string) => {
    return await Api.get(`/process/kiosk/qrscan_check_ticket?${queue}`);
  };

  static delQueue = async (payload: {
    service_id: string;
    ticket_id: string;
    branch_id: string;
  }) => {
    return await Api.post(`/process/kiosk/qrscan_cancel_ticket`, payload);
  };

  static checkClientID = async (id: string) => {
    localStorage.setItem(queryEnum.id, id);
    return await Api.get(
      `/process/kiosk/qrscan_check_client_id?client_id=${id}`
    );
  };
}
