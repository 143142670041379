import { useEffect, useRef } from "react";

const useClickOutside = (setOpen: (e: boolean) => void) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (divRef.current && !divRef.current.contains(target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return { divRef };
};

export default useClickOutside;
