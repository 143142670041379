import overallAPI from "../../../MainLayout/services/api";
import { useAppDispatch, useAppSelector } from "../../../Redux/store";
import { pageBookingEnum } from "../../../Types/routes.type";
import { callAPI, overallActions } from "../../../MainLayout/services/slice";

const useCancel = () => {
  const dispatch = useAppDispatch();
  const queue = useAppSelector((state) => state.overall.queue);

  const onCancel = () => {
    const action = async () => {
      const payload = {
        service_id: queue?.ticket.services[0] || "",
        ticket_id: queue?.ticket.id || "",
        branch_id: queue?.ticket.branch_id || "",
      };
      return await overallAPI.delQueue(payload);
    };

    const success = () => {
      dispatch(overallActions.setPageBooking(pageBookingEnum.info));
    };

    dispatch(callAPI(action, success));
  };

  return { onCancel };
};

export default useCancel;
