import { useAppDispatch } from "../../Redux/store";
import { callAPI, overallActions } from "../services/slice";
import overallAPI from "../services/api";

const useGetTable = () => {
  const dispatch = useAppDispatch();

  const getServices = (bKey: string) => {
    const getTable = async () => {
      return await overallAPI.getTables(bKey);
    };

    const getTableSuccess = (e: any) => {
      dispatch(overallActions.setServices(e.services));
      dispatch(overallActions.setServiceStatus(e.serving_status));
      dispatch(overallActions.setWaitingTime(e.predict_waiting_time));
    };

    dispatch(callAPI(getTable, getTableSuccess));
  };

  return { getServices };
};

export default useGetTable;
