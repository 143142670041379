import React from "react";
import ChangeLanguage from "./ChangeLanguage/ChangeLanguage";
import "./Header.scss";
import Image from "../../Components/Image/Image";
import { useAppDispatch } from "../../Redux/store";
import { overallActions } from "../services/slice";
import { pageBookingEnum } from "../../Types/routes.type";

const Header = () => {
  const dispatch = useAppDispatch();

  const goHome = () => {
    dispatch(overallActions.setPageBooking(pageBookingEnum.ticket));
  };

  return (
    <div className="layout-header">
      <Image onClick={goHome} className="layout-header-logo" />
      <ChangeLanguage />
    </div>
  );
};

export default React.memo(Header);
