import React, { ReactNode } from "react";
import "./CustomButton.scss";

interface PT {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const CustomButton: React.FC<PT> = ({
  children,
  onClick,
  disabled,
  className,
}) => {
  return (
    <button
      disabled={disabled}
      className={`customized-button${className ? " " + className : ""}`}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {children}
    </button>
  );
};

export default CustomButton;
