import React from "react";
import "./Buttons.scss";
import CustomButton from "../CustomButton/CustomButton";
import { useTranslation } from "react-i18next";

interface PT {
  onBack?: () => void;
  onSubmit?: () => void;
  backTitle?: string;
  submitTitle?: string;
  disabled?: boolean;
  className?: string;
}

const Buttons: React.FC<PT> = ({
  onBack,
  onSubmit,
  backTitle,
  submitTitle,
  disabled,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`buttons${className ? " " + className : ""}`}>
      {onBack && (
        <CustomButton className="back-button" onClick={onBack}>
          {t(backTitle ? backTitle : "Quay lại")}
        </CustomButton>
      )}
      {onSubmit && (
        <CustomButton disabled={disabled} onClick={onSubmit}>
          {t(submitTitle ? submitTitle : "OK")}
        </CustomButton>
      )}
    </div>
  );
};

export default React.memo(Buttons);
