import React from "react";
import "./Thanks.scss";
import { useTranslation } from "react-i18next";

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <div className="thanks">
      <div style={{ textAlign: "center" }}>
        {t("Quý khách vui lòng Scan mã QR tại nhà hàng để đặt lượt phục vụ")}
      </div>
      <div style={{ textAlign: "center" }}>{t("Xin cảm ơn quý khách")}</div>
    </div>
  );
};

export default React.memo(Thanks);
