import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageItem from "../../../../Components/LanguageItem/LanguageItem";
import { queryEnum } from "../../../../Types/global.type";
import Util from "../../../../Util/Util";
import { languageEnum } from "../../../services/const";

const languagesRaw = [
  { value: languageEnum.vn, label: "Tiếng Việt" },
  { value: languageEnum.en, label: "English" },
];

const useGetLanguage = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const storedLanguage = sessionStorage.getItem(queryEnum.lang);
  const { lang } = Util.getByQuery([queryEnum.lang]);

  const languages = languagesRaw.map((a) => {
    return { ...a, label: <LanguageItem {...a} /> };
  });

  const changeLanguage = (language: string | null) => {
    language && i18n.changeLanguage(language);
    sessionStorage.setItem(queryEnum.lang, language ? language : "");
    setSelectedLanguage(language);
  };

  useEffect(() => {
    switch (true) {
      case !!lang:
        sessionStorage.setItem(queryEnum.lang, lang);
        setSelectedLanguage(lang);
        break;

      case !!storedLanguage:
        sessionStorage.setItem(
          queryEnum.lang,
          storedLanguage ? storedLanguage : ""
        );
        setSelectedLanguage(storedLanguage);
        break;

      default:
        sessionStorage.setItem(queryEnum.lang, languagesRaw[0].value);
        setSelectedLanguage(languagesRaw[0].value);
        break;
    }
  }, []);

  return { languages, selectedLanguage, changeLanguage };
};

export default useGetLanguage;
