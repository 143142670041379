import React, { FC } from "react";
import "./Table.scss";
import { useTranslation } from "react-i18next";
import { serviceStatusType } from "../../../../MainLayout/services/type";
import { queryEnum } from "../../../../Types/global.type";
import Util from "../../../../Util/Util";

interface PT {
  status: serviceStatusType[];
}

const Table: FC<PT> = ({ status }) => {
  const { t } = useTranslation();
  const lang = sessionStorage.getItem(queryEnum.lang);

  return (
    <div className="que-table-wrap">
      <table className="que-table">
        <thead>
          <tr>
            <th style={{ width: "120rem" }}></th>
            <th style={{ width: "130rem" }}>{t("Current")}</th>
            <th style={{ width: "120rem" }}>{t("Group ahead")}</th>
          </tr>
        </thead>
        <tbody>
          {status.map((a, i) => (
            <tr key={i}>
              <td>{Util.get(`${lang}_name`, a)}</td>
              <td>{a.current}</td>
              <td>{a.groups_ahead}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Table);
