import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Redux/store";
import { callAPI, overallActions } from "../services/slice";
import overallAPI from "../services/api";
import { queryEnum } from "../../Types/global.type";
import { v4 as uuidv4 } from "uuid";
import { pageBookingEnum } from "../../Types/routes.type";
import { createdType } from "../services/type";

const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const localID = localStorage.getItem(queryEnum.id);

  const checkClientID = (bkey: string) => {
    const action = async () => {
      return await overallAPI.checkClientID(localID || uuidv4());
    };

    const success = (data: createdType) => {
      if (data) {
        dispatch(overallActions.setPageBooking(pageBookingEnum.ticket));
        dispatch(overallActions.setTicket(data));
      } else dispatch(overallActions.setPageBooking(pageBookingEnum.info));
    };

    if (!bkey) nav("/invalid-qr-code");
    else dispatch(callAPI(action, success));
  };

  return { checkClientID };
};

export default useCheckAuth;
