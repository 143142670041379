import React from "react";
import "./OverallLoading.scss";
import { useAppSelector } from "../../Redux/store";
import CustomModal from "../CustomModal/CustomModal";
import Image from "../Image/Image";

const OverallLoading = () => {
  const isLoading = useAppSelector((state) => state.overall.isLoading);

  return isLoading ? (
    <CustomModal isShowing={isLoading} className="loading-container">
      {/* <div className="loading-main loading-spin-y">
        <img src="./logo.png" />
      </div> */}
      <div className="loading-main loading-bounce">
        <Image className="loading-logo" />
      </div>
      {/* <div className="loading-main loading-beat">
        <img src="./logo.png" />
      </div>
      <div className="loading-main loading-spin-z">
        <img src="./logo.png" />
      </div>
      <div className="loading-main loading-cycle">
        <img src="./logo.png" />
      </div>
      <div className="loading-main loading-flip">
        <img src="./logo.png" />
      </div> */}
    </CustomModal>
  ) : (
    <></>
  );
};

export default React.memo(OverallLoading);
