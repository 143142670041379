import { ReactNode } from "react";

export interface MainRoutesType {
  path: string;
  element: ReactNode;
}

export enum basicMainRouteEnum {
  home = "/",
  created = "created",
}

export const mainRouteEnum = {
  ...basicMainRouteEnum,
};

export enum pageBookingEnum {
  home = "home",
  info = "info",
  que = "que",
  ticket = "ticket",
}
