import { MainRoutesType, mainRouteEnum } from "../Types/routes.type";
import NotFound from "../Components/NotFound/NotFound";
import Booking from "../Containers/Booking/Booking";
import Thanks from "../Components/Thanks/Thanks";

export const MainRoutes: MainRoutesType[] = [
  {
    path: mainRouteEnum.home,
    element: <Booking />,
  },
  {
    path: "/thank-you",
    element: <Thanks />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
