import React from "react";
import "./Booking.scss";
// import Home from "../Home/Home";
import Info from "../Info/Info";
import Que from "../Que/Que";
import { useAppSelector } from "../../Redux/store";
import { pageBookingEnum } from "../../Types/routes.type";
import Created from "../Created/Created";

const Booking = () => {
  const page = useAppSelector((state) => state.overall.pageBooking);

  switch (page) {
    case pageBookingEnum.info:
      return <Info />;

    case pageBookingEnum.que:
      return <Que />;

    case pageBookingEnum.ticket:
      return <Created />;

    default:
      return <Info />;
  }
};

export default React.memo(Booking);
