import React, { useState } from "react";
import "./CustomInput.scss";

interface PT {
  onChange: (e: string) => void;
  value?: string;
  placeholder?: string;
  errroMess?: string;
}

const CustomInput: React.FC<PT> = ({
  onChange,
  value,
  placeholder,
  errroMess,
}) => {
  const [renderValue, setRenderValue] = useState<string>("");

  const onHanldeChange = (e: string) => {
    onChange(e);
    if (value === undefined) setRenderValue(e);
  };

  return (
    <>
      <input
        className="customized-input"
        placeholder={placeholder}
        value={value !== undefined ? value : renderValue}
        onChange={(e) => {
          onHanldeChange(e.target.value);
        }}
      />
      {errroMess && (
        <div className="customized-input-errroMess">{errroMess}</div>
      )}
    </>
  );
};

export default React.memo(CustomInput);
