import React, { useEffect, useState } from "react";
import "./Info.scss";
import { useAppSelector } from "../../../Redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { queryEnum } from "../../../Types/global.type";
import { languageEnum, queueEnum } from "../../../MainLayout/services/const";

const Info = () => {
  const { t } = useTranslation();
  const [render, setRender] = useState(<></>);
  const queue = useAppSelector((state) => state.overall.queue);
  const lang = sessionStorage.getItem(queryEnum.lang);
  const timeFormat =
    lang === languageEnum.en ? "DD MMM YYYY, HH:mm" : "HH:mm, DD/MM/YYYY";

  useEffect(() => {
    switch (queue?.ticket.state) {
      case queueEnum.waiting:
        setRender(
          <ul>
            <li>{t("note1")}</li>
            <li>{t("note2")}</li>
            <li>{t("note3")}</li>
          </ul>
        );
        break;

      case queueEnum.cancelled:
        setRender(
          <div style={{ fontWeight: "bold", textAlign: "center" }}>
            {t("Your Number has Been Cancelled")}
          </div>
        );
        break;

      case queueEnum.serving:
        setRender(
          <>
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              {t(
                "Your queue number is being called please return to the store."
              )}
            </div>
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              {t("Xin cảm ơn quý khách")}
            </div>
          </>
        );
        break;

      case queueEnum.finished:
        setRender(
          <div style={{ fontWeight: "bold", textAlign: "center" }}>
            {t("Your order have been served")}
          </div>
        );
        break;

      default:
        setRender(<></>);
        break;
    }
  }, [queue]);

  return (
    <div>
      <div className="ticket-info">
        <div className="ticket-number">{queue?.ticket.cnum}</div>
        <div className="ticket-time">
          {t("Taken at")}{" "}
          {queue &&
            moment(Number(queue.ticket.ctime * 1000)).format(timeFormat)}
        </div>
      </div>
      <div className="ticket-note">{render}</div>
    </div>
  );
};

export default React.memo(Info);
