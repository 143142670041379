import React, { useEffect, useState } from "react";
import "./Created.scss";
import { useTranslation } from "react-i18next";
import Table from "../Que/components/Table/Table";
import Info from "./Info/Info";
import useCheckQueue from "./hooks/useCheckQueue";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { pageBookingEnum } from "../../Types/routes.type";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { queueEnum } from "../../MainLayout/services/const";
import useCancel from "./hooks/useCancel";
import CustomModal from "../../Components/CustomModal/CustomModal";
import Buttons from "../../Components/Buttons/Buttons";
import { overallActions } from "../../MainLayout/services/slice";
import { useNavigate } from "react-router-dom";

const Created = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [openConfirmDel, setOpenConfirmDel] = useState(false);
  const queue = useAppSelector((state) => state.overall.queue);
  const ticket = useAppSelector((state) => state.overall.ticket);

  const { showAnnouceTableLeft, onCheckQueue } = useCheckQueue();
  const { onCancel } = useCancel();

  useEffect(() => {
    showAnnouceTableLeft(Number(queue?.predict_wait_number));
  }, [queue]);

  useEffect(() => {
    if (ticket) onCheckQueue(true);
    const intervalId = setInterval(() => {
      if (ticket) onCheckQueue();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [ticket]);

  const goHome = () => {
    dispatch(overallActions.setPageBooking(pageBookingEnum.info));
    nav("/thank-you");
  };

  return (
    <div className="created-wrap">
      <div className="created">
        <div className="que-title">{t("Lấy số xếp hàng").toUpperCase()}</div>
        <div className="que-count">
          <div className="que-title">{t("YAKINIKU")}</div>
        </div>
        <Table
          status={[
            {
              vn_name: queue?.ticket.service_vn_name || "",
              en_name: queue?.ticket.service_en_name || "",
              jp_name: "",
              cn_name: "",
              sp_name: "",
              current: queue?.current_ticket || "",
              groups_ahead: queue?.predict_wait_number || "",
            },
          ]}
        />
        <Info />
      </div>
      {queue?.ticket.state !== queueEnum.waiting ? (
        <CustomButton onClick={goHome} className="created-btn">
          {t("take new queue").toUpperCase()}
        </CustomButton>
      ) : (
        <CustomButton
          onClick={() => {
            setOpenConfirmDel(true);
          }}
          className="created-btn"
        >
          {t("cancel").toUpperCase()}
        </CustomButton>
      )}
      <CustomModal
        isShowing={openConfirmDel}
        onCancel={() => {
          setOpenConfirmDel(false);
        }}
        className="error-modal-container"
      >
        <div className="error-modal-main">{t("confim del")}</div>
        <Buttons
          onBack={() => {
            setOpenConfirmDel(false);
          }}
          onSubmit={onCancel}
          className="que-take-btn cancel-btns"
          submitTitle={t("OK").toUpperCase()}
          backTitle={t("EXIT").toUpperCase()}
        />
      </CustomModal>
    </div>
  );
};

export default React.memo(Created);
