import { useAppDispatch } from "../../Redux/store";
import { callAPI, overallActions } from "../services/slice";
import overallAPI from "../services/api";
import { restaurantType } from "../services/type";

const useGetRestaurant = () => {
  const dispatch = useAppDispatch();

  const onGetRestaurant = () => {
    const action = async () => {
      return await overallAPI.getRestaurants();
    };

    const success = (e: restaurantType[]) => {
      dispatch(overallActions.setRestaurants(e));
    };

    dispatch(callAPI(action, success));
  };

  return { onGetRestaurant };
};

export default useGetRestaurant;
