import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../Translate/en.json";
import translationVI from "../Translate/vn.json";
import { queryEnum } from "../Types/global.type";
import Util from "./Util";
import { languageEnum } from "../MainLayout/services/const";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  vn: {
    translation: translationVI,
  },
};

const storedLanguage = sessionStorage.getItem(queryEnum.lang);
const { lang } = Util.getByQuery([queryEnum.lang]);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang ? lang : storedLanguage ? storedLanguage : languageEnum.vn, // default language
    fallbackLng: languageEnum.vn,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
