import React, { Dispatch, FC, SetStateAction } from "react";
import "./TermModal.scss";
import Modal from "antd/lib/modal/Modal";
import { useTranslation } from "react-i18next";

interface PT {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const TermModal: FC<PT> = ({ isOpen, setOpen }) => {
  const { t } = useTranslation();

  const outTerm = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={outTerm}
      closable={false}
      footer={null}
      width={window.innerWidth * 0.8}
    >
      <div className="term-title">{t("term title")}</div>
      <ol className="term">
        <li>{t("term 1")}</li>
        <li>{t("term 2")}</li>
        <li>{t("term 3")}</li>
        <li>{t("term 4")}</li>
        <li>{t("term 5")}</li>
      </ol>
    </Modal>
  );
};

export default React.memo(TermModal);
