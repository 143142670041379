import React, { useEffect } from "react";
import "./MainLayout.scss";
import ErrorModal from "../Components/ErrorModal/ErrorModal";
import OverallLoading from "../Components/OverallLoading/OverallLoading";
import { Route, Routes } from "react-router-dom";
import { MainRoutes } from "../Routes/routes";
import Header from "./Header/Header";
import useGetRestaurant from "./hooks/useGetRestaurant";
import useGetBkey from "./hooks/useGetBkey";
import useCheckAuth from "./hooks/useCheckAuth";

const MainLayout = () => {
  const { onGetRestaurant } = useGetRestaurant();
  const { checkClientID } = useCheckAuth();
  const { getKey } = useGetBkey();

  useEffect(() => {
    onGetRestaurant();
    checkClientID(getKey());
  }, []);

  return (
    <div className="main-layout">
      {/* Phần chính */}
      <Header />

      {/* Routers */}
      <Routes>
        {MainRoutes.map((a, index) => (
          <Route key={index} {...a} />
        ))}
      </Routes>

      {/* Các supporting modal */}
      <ErrorModal />
      <OverallLoading />
    </div>
  );
};

export default React.memo(MainLayout);
