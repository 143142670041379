import React, { ReactNode, useEffect, useState } from "react";
import "./CustomDropdown.scss";
import CustomButton from "../CustomButton/CustomButton";
import DropdownList from "./Components/DropdownList/DropdownList";
import { optionType } from "../../Types/global.type";
import DropdownPlaceholder from "./Components/DropdownPlaceholder/DropdownPlaceholder";
import useClickOutside from "./hooks/useClickOutside";

interface PT {
  title: string;
  options: optionType[];
  selected: string | null;
  onChange: (e: string | null) => void;
  className?: string;
}

const CustomDropdown: React.FC<PT> = ({
  title,
  options,
  selected,
  onChange,
  className,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<ReactNode | null>(null);
  const { divRef } = useClickOutside(setOpen);

  useEffect(() => {
    const found = options.find((a) => a.value === selected);
    if (found) setSelectedLabel(found.label);
    else setSelectedLabel(null);
  }, [selected]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = (e: string | null) => {
    onChange(e);
    setOpen(false);
  };

  return (
    <div
      ref={divRef}
      className={`custom-dropdown${className ? " " + className : ""}`}
    >
      <CustomButton onClick={handleToggle} className="dropdown-button">
        <DropdownPlaceholder
          title={title}
          selectedLabel={selectedLabel}
          isOpen={isOpen}
        />
      </CustomButton>
      <DropdownList
        isOpen={isOpen}
        handleClose={handleClose}
        options={options}
        selected={selected}
      />
    </div>
  );
};

export default React.memo(CustomDropdown);
