import React from "react";
import CustomDropdown from "../../../Components/CustomDropdown/CustomDropdown";
import useGetLanguage from "./hooks/useGetLanguage";
import "./ChangeLanguage.scss";

const ChangeLanguage = () => {
  const { languages, selectedLanguage, changeLanguage } = useGetLanguage();

  return (
    <CustomDropdown
      title="Chọn ngôn ngữ"
      options={languages}
      selected={selectedLanguage}
      onChange={changeLanguage}
      className="language-button"
    />
  );
};

export default React.memo(ChangeLanguage);
