import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "./CustomModal.scss";
import useClickOutside from "./hooks/useQuickClose";

interface PT {
  children: ReactNode;
  isShowing: boolean;
  onCancel?: () => void;
  className?: string;
}

const CustomModal: React.FC<PT> = ({
  children,
  isShowing,
  onCancel,
  className,
}) => {
  const {} = useClickOutside(isShowing, onCancel);

  return isShowing ? (
    ReactDOM.createPortal(
      <React.Fragment>
        <div className="custom-modal-overlay" />
        <div
          className="custom-modal-wrapper"
          aria-modal
          aria-hidden
          tabIndex={-1}
          role="dialog"
        >
          <div>
            <div className={`custom-modal${className ? " " + className : ""}`}>
              {children}
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  ) : (
    <></>
  );
};

export default React.memo(CustomModal);
