import React from "react";
import "./ErrorModal.scss";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import Buttons from "../Buttons/Buttons";
import CustomModal from "../CustomModal/CustomModal";
import { overallActions } from "../../MainLayout/services/slice";

const ErrorModal = () => {
  const dispatch = useAppDispatch();
  const isErrorModalOpen = useAppSelector(
    (state) => state.overall.isErrorModalOpen
  );
  const errorMess = useAppSelector((state) => state.overall.errorMess);

  const onClose = () => {
    dispatch(overallActions.setErrorModalClose());
  };

  return isErrorModalOpen ? (
    <CustomModal
      isShowing={isErrorModalOpen}
      onCancel={onClose}
      className="error-modal-container"
    >
      <div className="error-modal-main">{errorMess}</div>
      <Buttons onSubmit={onClose} />
    </CustomModal>
  ) : (
    <></>
  );
};

export default React.memo(ErrorModal);
