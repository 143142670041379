export enum queueEnum {
  waiting = "waiting",
  cancelled = "cancelled",
  serving = "serving",
  finished = "finished",
}

export enum languageEnum {
  en = "en",
  vn = "vn",
}
