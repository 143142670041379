import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { HashRouter } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import "./Style/theme.scss";
import MainLayout from "./MainLayout/MainLayout";
import { I18nextProvider } from "react-i18next";
import i18n from "./Util/Translate";
import "antd/dist/reset.css";
import { ConfigProvider } from "antd";
import locale from "antd/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
dayjs.locale("vi");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <ConfigProvider locale={locale}>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
      <HashRouter>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <MainLayout />
          </I18nextProvider>
        </Provider>
      </HashRouter>
    </ConfigProvider>
  </ErrorBoundary>
);
