import React, { useState } from "react";
import "./Info.scss";
import CustomInput from "../../Components/CustomInput/CustomInput";
import { pageBookingEnum } from "../../Types/routes.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { overallActions } from "../../MainLayout/services/slice";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TermModal from "./TermModal/TermModal";
import Image from "../../Components/Image/Image";
import Buttons from "../../Components/Buttons/Buttons";
import Util from "../../Util/Util";

const Info = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isOpenTerm, setOpenTerm] = useState(false);
  const [checkTerm, setCheckTerm] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const name = useAppSelector((state) => state.overall.name);
  const phone = useAppSelector((state) => state.overall.phone);

  const setName = (e: string) => {
    dispatch(overallActions.setName(e));
  };

  const setPhone = (e: string) => {
    if (!isNaN(Number(e))) {
      if (!Util.checkValidatedPhone(e)) {
        setPhoneError("invalid phone");
      } else setPhoneError("");
      dispatch(overallActions.setPhone(e));
    }
  };

  const goToQueue = () => {
    dispatch(overallActions.setPageBooking(pageBookingEnum.que));
  };

  // const goBack = () => {
  //   dispatch(overallActions.setPageBooking(pageBookingEnum.info));
  // };

  const goTerm = () => {
    setOpenTerm(true);
  };

  const onCheckTerm = (e: any) => {
    setCheckTerm(e.target.checked);
  };

  return (
    <div className="info">
      <div className="info-inputs">
        <Image className="info-logo" />
        <CustomInput
          onChange={setName}
          placeholder={`${t("Họ và tên")} *`}
          value={name}
        />
        <div className="booking-phone">
          <CustomInput
            value={phone}
            onChange={setPhone}
            placeholder={`${t("Số điện thoại")} *`}
          />
          <div className="booking-phone-error">{t(phoneError)}</div>
        </div>
        <Checkbox onChange={onCheckTerm}>
          {t("Tôi đồng ý với")}{" "}
          <span className="info-term" onClick={goTerm}>
            {t("điều khoản và điều kiện")}
          </span>
        </Checkbox>
      </div>
      <Buttons
        // onBack={goBack}
        onSubmit={goToQueue}
        className="info-btn"
        disabled={
          name === "" || phone === "" || !checkTerm || phoneError !== ""
        }
        submitTitle={t("next").toUpperCase()}
        backTitle={t("Quay lại").toUpperCase()}
      />

      <TermModal isOpen={isOpenTerm} setOpen={setOpenTerm} />
    </div>
  );
};

export default React.memo(Info);
