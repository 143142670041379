import { ReactNode } from "react";

export interface resWithPaginationType<T> {
  hits: T[];
  dataExtra?: any;
  pagination: {
    totalRows: number;
    totalPages: number;
  };
}

export interface optionType {
  value: string;
  label: ReactNode;
}

export enum queryEnum {
  lang = "lang",
  id = "client_id",
}
