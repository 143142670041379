import React from "react";
import "./DropdownList.scss";
import { optionType } from "../../../../Types/global.type";
import DropdownItem from "../DropdownItem/DropdownItem";

interface PT {
  isOpen: boolean;
  handleClose: (e: string | null) => void;
  options: optionType[];
  selected: string | null;
}

const DropdownList: React.FC<PT> = ({
  isOpen,
  handleClose,
  options,
  selected,
}) => {
  return (
    <div
      className={`dropdown-list ${
        isOpen ? "dropdown-list-open" : "dropdown-list-close"
      }`}
    >
      <ul>
        {options.map((a, i) => (
          <DropdownItem
            key={i}
            data={a}
            handleClose={handleClose}
            selected={selected}
          />
        ))}
      </ul>
    </div>
  );
};

export default React.memo(DropdownList);
