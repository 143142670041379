import { useEffect } from "react";

const useClickOutside = (isShowing: boolean, onCancel?: () => void) => {
  useEffect(() => {
    const onKeyDown = (e: any) => {
      e.keyCode === 27 && onCancel && onCancel();
    };

    isShowing
      ? addEventListener("keydown", onKeyDown)
      : removeEventListener("keydown", onKeyDown);

    return () => {
      removeEventListener("keydown", onKeyDown);
    };
  }, [isShowing]);

  return {};
};

export default useClickOutside;
