const useGetBkey = () => {
  const getKey = () => {
    let bkey = "";
    if (!bkey) {
      const bKeyMatch = window.location.hash.match(/bKey=([^&]*)/);
      if (bKeyMatch) {
        bkey = bKeyMatch[1];
      }
    }

    return bkey;
  };

  return { getKey };
};

export default useGetBkey;
