import { createSlice } from "@reduxjs/toolkit";
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import {
  createdType,
  overallState,
  queueType,
  restaurantType,
  serviceStatusType,
  serviceType,
} from "./type";
import Util from "../../Util/Util";

const initialState: overallState = {
  isLoading: false,
  isLoadingHome: false,
  errorMess: "",
  isErrorModalOpen: false,

  services: [],
  serviceStatus: [],
  restaurants: [],

  queue: null,
  name: "",
  phone: "",
  pageBooking: "",
  ticket: null,
  waitingTime: 0,
};

export const overallSlice = createSlice({
  name: "overall",
  initialState,
  reducers: {
    reset: () => initialState,

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setLoadingHome: (state, action: PayloadAction<boolean>) => {
      state.isLoadingHome = action.payload;
    },

    setErrorMess: (state, action: PayloadAction<ReactNode>) => {
      state.errorMess = action.payload;
      state.isErrorModalOpen = true;
    },

    setErrorModalClose: (state) => {
      state.isErrorModalOpen = false;
    },

    setRestaurants: (state, action: PayloadAction<restaurantType[]>) => {
      state.restaurants = action.payload;
    },

    setServices: (state, action: PayloadAction<serviceType[]>) => {
      state.services = action.payload;
    },

    setServiceStatus: (state, action: PayloadAction<serviceStatusType[]>) => {
      state.serviceStatus = action.payload;
    },

    setQueue: (state, action: PayloadAction<queueType | null>) => {
      state.queue = action.payload;
    },

    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },

    setPageBooking: (state, action: PayloadAction<string>) => {
      state.pageBooking = action.payload;
    },

    setTicket: (state, action: PayloadAction<createdType | null>) => {
      state.ticket = action.payload;
    },

    setWaitingTime: (state, action: PayloadAction<number>) => {
      state.waitingTime = action.payload;
    },
  },
});

export const overallReducer = overallSlice.reducer;
export const overallActions = overallSlice.actions;

export const callAPI =
  (
    func: () => Promise<any>,
    onSuccess: (e: any) => void,
    onFInally?: () => void,
    onError?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await func();

      if (res.status === "success") onSuccess(res.data);
      else {
        Util.showCodeMessWithModal(res, dispatch);
        onError && onError();
      }
    } catch {
      onError && onError();
    } finally {
      onFInally && onFInally();
    }
  };
