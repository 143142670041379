import React, { useEffect, useState } from "react";
import "./Que.scss";
import Table from "./components/Table/Table";
import Count from "./components/Count/Count";
import { useTranslation } from "react-i18next";
import useTakeQueue from "./hooks/useTakeQueue";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import Buttons from "../../Components/Buttons/Buttons";
import { pageBookingEnum } from "../../Types/routes.type";
import { overallActions } from "../../MainLayout/services/slice";
import useGetBkey from "../../MainLayout/hooks/useGetBkey";
import useGetTable from "../../MainLayout/hooks/useGetTable";

const Que = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [count, setCount] = useState(1);
  const serviceStatus = useAppSelector((state) => state.overall.serviceStatus);
  const waitingTime = useAppSelector((state) => state.overall.waitingTime);

  const { getKey } = useGetBkey();
  const { onTakeQueue } = useTakeQueue(count);
  const { getServices } = useGetTable();

  const goBack = () => {
    dispatch(overallActions.setPageBooking(pageBookingEnum.info));
  };

  useEffect(() => {
    getServices(getKey());
  }, []);

  return (
    <div className="que-take-btn-wrap">
      <div>
        <div className="que-title">{t("Lấy số xếp hàng").toUpperCase()}</div>
        <div className="que-count">
          <div className="que-title">{t("Yakiniku UAT")}</div>
          <div>
            <div className="que-est">
              {t("estimated-wait-time").toUpperCase()}
            </div>
            <div className="que-time">
              {waitingTime} {t("min")}
            </div>
          </div>
        </div>
        <Table status={serviceStatus} />
        <div className="que-title">{t("NumPLP")}</div>
        <Count setCount={setCount} count={count} />

        <div className="que-note">
          This site is protected by reCAPTCHA and the Google{" "}
          <span>Privacy Policy</span> and <span>Terms of Service apply</span>.
        </div>
      </div>
      <Buttons
        onBack={goBack}
        onSubmit={onTakeQueue}
        className="que-take-btn"
        submitTitle={t("take queue").toUpperCase()}
        backTitle={t("Quay lại").toUpperCase()}
      />
    </div>
  );
};

export default React.memo(Que);
