import React, { ReactNode } from "react";
import "./DropdownPlaceholder.scss";

interface PT {
  title: string;
  selectedLabel: ReactNode | null;
  isOpen: boolean;
}

const DropdownPlaceholder: React.FC<PT> = ({
  title,
  selectedLabel,
  isOpen,
}) => {
  return (
    <div
      className={`dropdown-placeholder ${
        selectedLabel
          ? "dropdown-placeholder-selected"
          : "dropdown-placeholder-unselected"
      }`}
    >
      {selectedLabel ? selectedLabel : title}
      <div
        className={`dropdown-placeholder-icon${
          isOpen ? " dropdown-placeholder-icon-open" : ""
        }`}
      ></div>
    </div>
  );
};

export default React.memo(DropdownPlaceholder);
