import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/store";
import { callAPI, overallActions } from "../../../MainLayout/services/slice";
import overallAPI from "../../../MainLayout/services/api";
import { pageBookingEnum } from "../../../Types/routes.type";
import { useTranslation } from "react-i18next";
import { queueType } from "../../../MainLayout/services/type";
import { queueEnum } from "../../../MainLayout/services/const";

const useCheckQueue = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [annouceLeft, setAnnouceLeft] = useState(3);
  const ticket = useAppSelector((state) => state.overall.ticket);

  const showAnnouceTableLeft = (tableLeft: number) => {
    if (tableLeft <= 3 && tableLeft > 0 && annouceLeft === tableLeft) {
      setAnnouceLeft((prev) => prev - 1);
      dispatch(
        overallActions.setErrorMess(t("more left", { left: tableLeft }))
      );
    }
  };

  const onCheckQueue = (isINit?: boolean) => {
    const action = async () => {
      const queueQuery = `branch_id=${ticket?.branch_id}&ticket_number=${ticket?.cnum}&ticket_id=${ticket?.id}`;

      return await overallAPI.checkQueue(queueQuery || "");
    };

    const success = (e: queueType) => {
      dispatch(overallActions.setQueue(e));
      if (isINit && e.ticket.state === queueEnum.waiting) {
        setAnnouceLeft(Math.min(3, Number(e.predict_wait_number)));
        dispatch(
          overallActions.setErrorMess(
            <>
              <div style={{ textAlign: "center" }}>{t("call soon")}</div>
              <div style={{ textAlign: "center" }}>{t("wait please")}</div>
              <div style={{ textAlign: "center" }}>{t("thanks")}</div>
            </>
          )
        );
      }
    };

    const actionFinally = () => {
      dispatch(overallActions.setLoading(false));
    };

    const error = () => {
      dispatch(overallActions.setErrorMess("Không tìm thấy dữ liệu đặt bàn"));
      dispatch(overallActions.setPageBooking(pageBookingEnum.info));
    };

    dispatch(callAPI(action, success, actionFinally, error));
  };

  return { showAnnouceTableLeft, onCheckQueue };
};

export default useCheckQueue;
