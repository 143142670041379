import React, { FC } from "react";
import "./Image.scss";

interface PT {
  className: string;
  onClick?: () => void;
}

const Image: FC<PT> = ({ className, onClick }) => {
  return <div className={`custom-image ${className}`} onClick={onClick}></div>;
};

export default React.memo(Image);
